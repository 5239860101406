import React, { useState } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import { ReactComponent as StarIcon } from "images/star-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-5.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-7.svg";

const HeaderRow = tw.div`flex justify-between items-center flex-col xl:flex-row`;
const Header = tw(SectionHeading)``;
const TabsControl = tw.div`flex flex-wrap bg-gray-200 px-2 py-2 rounded leading-none mt-12 xl:mt-0`;

const TabControl = styled.div`
  ${tw`cursor-pointer px-6 py-3 mt-2 sm:mt-0 sm:mr-2 last:mr-0 text-gray-600 font-medium rounded-sm transition duration-300 text-sm sm:text-base w-1/2 sm:w-auto text-center`}
  &:hover {
    ${tw`bg-gray-300 text-gray-700`}
  }
  ${props => props.active && tw`bg-pink-700! text-gray-100!`}
  }
`;

const TabContent = tw(motion.div)`mt-6 flex flex-wrap sm:-mr-10 md:-mr-6 lg:-mr-12`;
const CardContainer = tw.div`mt-10 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 sm:pr-10 md:pr-6 lg:pr-12`;
const Card = tw(motion.a)`bg-gray-200 rounded-b block max-w-xs mx-auto sm:max-w-none sm:mx-0`;
const CardImageContainer = styled.div`
  ${props => css`background-image: url("${props.imageSrc}");`}
  ${tw`h-56 xl:h-64 bg-center bg-cover relative rounded-t`}
`;
const CardRatingContainer = tw.div`leading-none absolute inline-flex bg-gray-100 bottom-0 left-0 ml-4 mb-4 rounded-full px-5 py-2 items-end`;
const CardRating = styled.div`
  ${tw`mr-1 text-sm font-bold flex items-end`}
  svg {
    ${tw`w-4 h-4 fill-current text-orange-400 mr-1`}
  }
`;

const CardHoverOverlay = styled(motion.div)`
  background-color: rgba(255, 255, 255, 0.5);
  ${tw`absolute inset-0 flex justify-center items-center`}
`;
const CardButton = tw(PrimaryButtonBase)`text-sm`;


const CardText = tw.div`p-4 text-gray-900`;
const CardTitle = tw.h5`text-lg font-semibold group-hover:text-pink-700`;
const CardContent = tw.p`mt-1 text-sm font-medium text-gray-600`;


const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-64 w-64 opacity-15 transform translate-x-2/3 -translate-y-12 text-pink-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-80 w-80 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;

export default ({
  heading = "Checkout the Menu",
  tabs = {
    Devops: [
      {
        imageSrc:
        "/images/one.jpg",
        title: "Devops",
        content: "Parcours DevOps pour forger cette culture au sein des entreprises et vous donne les outils nécessaires pour chaque phase depuis la conception et la planification jusqu'au déploiement et au monitoring.",
        price: "$5.99",
        rating: "5.0",
        reviews: "87",
        url: "Devops"
      },
      {
        imageSrc:
        "/images/one.jpg",
        title: "Gestion de Projet",
        content: "Vous gérez déjà des projets sur le terrain, mais vous souhaitez développer vos compétences ? Ou vous avez encore peu d’expérience, et vous voulez acquérir les connaissances pour développer un programme de travail en équipe ? Ce module de formation vous présentera les bases du management des projets.",
        price: "$2.99",
        rating: "4.8",
        reviews: "32",
        url: "#"
      },
      {
        imageSrc:
        "/images/one.jpg",
        title: "Telecom",
        content: "Aujourd’hui, le secteur des télécommunications se porte mieux que jamais avec l’avènement perpétuel de nouvelles technologies de transmission, d’émission et de réception. Groupe IDYAL vous aide à trouver votre formation télécommunication diplômante ou qualifiante !",
        price: "$7.99",
        rating: "4.9",
        reviews: "89",
        url: "#"
      },
      {
        imageSrc:
        "/images/one.jpg",
        title: "Réseaux",
        content: "Le développement des TIC s’accompagne d’une croissance extraordinaire des appareils interconnectés grâce à des serveurs et réseaux locaux et étendus (internet, privé), ce qui crée une forte offre d’emploi pour ce domaine de compétence.Grace a cette formation devenez des spécialistes en infrastructures et réseaux des ordinateurs (postes de travail et serveur).",
        price: "$8.99",
        rating: "4.6",
        reviews: "12",
        url: "#"
      },
      // {
      //   imageSrc:
      //   "/images/one.jpg",
      //   title: "Developper",
      //   content: "Un cursus qui permet d’acquérir toutes les connaissances informatiques nécessaires à la création de logiciels et à la réalisation de projet web. Les professionnels du développement web construisent la structure informatique d’une application, d’un logiciel ou d’une page web grâce au code",
      //   price: "$7.99",
      //   rating: "4.2",
      //   reviews: "19",
      //   url: "#"
      // },
      // {
      //   imageSrc:
      //   "/images/one.jpg",
      //   title: "Management",
      //   content: "Description",
      //   price: "$2.99",
      //   rating: "5.0",
      //   reviews: "61",
      //   url: "#"
      // },
      // {
      //   imageSrc:
      //   "/images/one.jpg",
      //   title: "Devops",
      //   content: "Description",
      //   price: "$3.99",
      //   rating: "4.2",
      //   reviews: "95",
      //   url: "#"
      // },
      // {
      //   imageSrc:
      //   "/images/one.jpg",
      //   title: "Gestion de Projet",
      //   content: "Description",
      //   price: "$3.99",
      //   rating: "3.9",
      //   reviews: "26",
      //   url: "#"
      // }
    ],
    Télécom: getRandomCards(),
    GestionProjet: getRandomCards(),
    Developper: getRandomCards()
  }
}) => {

  const tabsKeys = Object.keys(tabs);
  const [activeTab, setActiveTab] = useState(tabsKeys[0]);

  return (
    <Container>
      <ContentWithPaddingXl>
        <HeaderRow>
          <Header>{heading}</Header>
          <TabsControl>
            {Object.keys(tabs).map((tabName, index) => (
              <TabControl key={index} active={activeTab === tabName} onClick={() => setActiveTab(tabName)}>
                {tabName}
              </TabControl>
            ))}
          </TabsControl>
        </HeaderRow>

        {tabsKeys.map((tabKey, index) => (
          <TabContent
            key={index}
            variants={{
              current: {
                opacity: 1,
                scale:1,
                display: "flex",
              },
              hidden: {
                opacity: 0,
                scale:0.8,
                display: "none",
              }
            }}
            transition={{ duration: 0.4 }}
            initial={activeTab === tabKey ? "current" : "hidden"}
            animate={activeTab === tabKey ? "current" : "hidden"}
          >
            {tabs[tabKey].map((card, index) => (
              <CardContainer key={index}>
                <Card className="group" href={card.url} initial="rest" whileHover="hover" animate="rest">
                  <CardImageContainer imageSrc={card.imageSrc}>
                    <CardRatingContainer>
                      <CardRating>
                        <StarIcon />
                        {/* {card.rating} */}
                      </CardRating>
                      {/* <CardReview>({card.reviews})</CardReview> */}
                    </CardRatingContainer>
                    <CardHoverOverlay
                      variants={{
                        hover: {
                          opacity: 1,
                          height: "auto"
                        },
                        rest: {
                          opacity: 0,
                          height: 0
                        }
                      }}
                      transition={{ duration: 0.3 }}
                    >
                      <CardButton>Participer</CardButton>
                    </CardHoverOverlay>
                  </CardImageContainer>
                  <CardText>
                    <CardTitle>{card.title}</CardTitle>
                    <CardContent>{card.content}</CardContent>
                    {/* <CardPrice>{card.price}</CardPrice> */}
                  </CardText>
                </Card>
              </CardContainer>
            ))}
          </TabContent>
        ))}
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};


const getRandomCards = () => {
  const cards = [
    {
      imageSrc:
      "/images/one.jpg",
      title: "Devops",
      // content: "Tomato Salad & Carrot",
      url: "#"
    },
    {
      imageSrc:
      "/images/one.jpg",
      title: "Gestion de Projet",
      // content: "Cheese Pizza",
      url: "#"
    },
    {
      imageSrc:
      "/images/one.jpg",
      title: "Telecom",
      // content: "Hamburger & Fries",
      url: "#"
    },
    {
      imageSrc:
      "/images/one.jpg",
      title: "Réseaux",
      // content: "Crispy Soyabeans",
      url: "#"
    },
    {
      imageSrc:
      "/images/one.jpg",
      title: "Developper",
      // content: "Roasted Chicken & Egg",
      url: "#"
    },
    {
      imageSrc:
      "/images/one.jpg",
      title: "Management",
      // content: "Deepfried Chicken",
      url: "#"
    },
    {
      imageSrc:
      "/images/one.jpg",
      title: "Devops",
      // content: "Mexican Chilli",
      url: "#"
    },
    {
      imageSrc:
      "/images/one.jpg",
      title: "Gestion de Projet",
      // content: "Chilli Crispy Nachos",
      url: "#"
    }
  ];

  // Shuffle array
  return cards.sort(() => Math.random() - 0.5);
};
