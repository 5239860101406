import React, { useState } from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { SectionHeading } from "components/misc/Headings.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeatures from "components/features/TwoCol.js";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import MainFeature2 from "components/features/TwoColSingleFeatureWithStats2.js";
import styled from "styled-components";

export default () => {
  const [setVisible] = useState(7);
  const onLoadMoreClick = () => {
    setVisible(v => v + 6);
  };
  const Heading = tw(SectionHeading)`w-full`;
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const SectionDescriptions = tw(DescriptionBase)`text-center mx-auto text-gray-600 max-w-4xl`;
  const HighlightedText = tw.span`bg-pink-700 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;
  const Text = styled.div`
  ${tw`text-lg  text-gray-600`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-0`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;


  return (
    <AnimationRevealPage>
      <Heading>Notre travail en <HighlightedText><span tw="text-pink-700">Vedette</span></HighlightedText></Heading>
      <SectionDescriptions>
      Nos stratégies omnicanal offrent une expérience utilisateur transparente sur votre site Web qui promet du trafic et de la conversion. Que votre marque ait besoin de services de développement Web, developpement d'application mobile ou de développement de logiciels, nos professionnels de l'agence numérique ont la capacité de façonner leurs compétences pour donner vie à votre projet unique.
      </SectionDescriptions>
      <MainFeatures
        subheading={<Subheading> </Subheading>}
        heading={
          <>
            Gestion du Controle
            <wbr /> <HighlightedText>Fiscal.</HighlightedText>
          </>
        }
        description={
          <Description>
            Ce progiciel de dématérialisation de toutes les procédures du controle fiscal est composé de 3 Applications.
            
        
          <Text>
            {/* <br/>COMPOSANTES :<br/> */}
            
              <ul>
                <li>
                  <p>WEB / GED / ALGORITHME DE SELECTION</p>
                </li>
                <li>
                  <p>GESTION DES CONTRIBUABLES</p>
                </li>
                <li>
                  <p>PROGRAMMATION</p>
                </li>
                <li>
                  <p>CONTROLE</p>
                </li>
                <li>
                  <p>PILOTAGE ET SUIVI</p>
                </li>
                <li>
                  <p>INDICATEURS D'EVALUATION STATISTIQUES</p>
                </li>
                <li>
                  <p>ADMINISTRATION & PARAMETRAGE</p>
                </li>
                <li>
                  <p>AUDIT</p>
                </li>
                
              </ul>
              {/* {isExpanded && (
               <Text>
               <p>Ce paragraphe est affiché uniquement si le bouton "Voir plus" a été cliqué.</p>
             </Text>
           )}
              <Button onClick={handleExpandClick}>Voir plus</Button> */}
            </Text>
            

            {/* <strong>WEB / GED / ALGORITHME DE SELECTION / ADMINISTRATION PARAMETRAGE / PILOTAGE ET SUIVI / INDICATEURS D’ÉVALUATIONS (STATISTIQUE) / PROGRAMMATION / CONTRÔLE  STATISTIQUES (TABLEAU DE BORD) / CONTRIBUABLE / AUDIT INTERCONEXION.</strong> */}
          </Description>
        }
        
        buttonRounded={false}
        textOnLeft={false}
        onClick={onLoadMoreClick} primaryButtonText="Voir plus"
        primaryButtonUrl="/siscofi"
        imageSrc={
          "/images/cap-siscofi.png"
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* <Blog /> */}
      {/* <Hero /> */}
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
     
      
      <MainFeature2
        subheading={<Subheading></Subheading>}
        heading={<>Orange <HighlightedText>Drive</HighlightedText></>}
        description={
          <Description>
            Orange Drive est une solution de stockage, virtuelle, personnalisée et privée.
            Elle est composée de trois environnements clients (Web, Mobile Andoid et IOS.) et un environnement management (Backoffice).
            <Text>
              <ul>
                <li>
                  <p>
                    Photos 
                  </p>
                </li>
                <li>
                  <p>
                    Vidéos 
                  </p>
                </li>
                <li>
                  <p>
                    Audios
                  </p>
                </li>
                <li>
                  <p>
                    Contacts
                  </p>
                </li>
                <li>
                  <p>
                    Journal d’appel
                  </p>
                </li>
                <li>
                  <p>
                    SMS
                  </p>
                </li>
                <li>
                  <p>
                    Documents (Word, Excel, Powerpont, PDF)
                  </p>
                </li>
              </ul>
            </Text>
            {/* • Photos
            • Vidéos
            • Audios
            • Contacts
            • Journal d’appel
            • SMS
            • Documents (Word, Excel, Powerpont, PDF). */}
            
            
             
          </Description>
        }
        statistics={[
         
        ]}
        primaryButtonText="Voir plus"
        primaryButtonUrl="/Orangedrive"
        imageInsideDiv={false}
        imageSrc="/images/odrive.png"
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      {/* <Hero /> */}
      <MainFeature
        subheading={<Subheading></Subheading>}
        heading={
          <>
            Gestion du Recouvrement
            <wbr /> <HighlightedText>SIPAR</HighlightedText>
          </>
        }
        description={
          <Description>
            Un logiciel de dématérialisation de toute la chaine du recouvrement fiscal. Il est composé de deux applications :
            <br />
            <Text>
            <ul>
              <li>
                <p>
                  WEB & GED
                </p>
              </li>
              <li>
                <p>
                  Gestion des prises en charge (planification et Suivie)
                </p>
              </li>
              <li>
                <p>
                  Formalité d'enrégistrement 
                </p>
              </li>
              <li>
                <p>
                  Encaissement 
                </p>
              </li>
              <li>
                <p>
                  Contentieux
                </p>
              </li>
              <li>
                <p>
                  Comptabilité 
                </p>
              </li>
              <li>
                <p>
                  Tableau de Bord
                </p>
              </li>
            </ul>
           </Text>
            {/* COMPOSANTES : <br/>
            <strong>
            WEB / 
            GED  

            / Module « Gestion des prises en charge »  
            / Module « Pilotage (planification et suivi) » 
            / Module « Formalités d’enregistrement » 
            / Module « Encaissement » 
            / Module « Contentieux » 
            / Module « Comptabilité et Statistiques »  
            / Module « Tableau de Bord »
            / Module « Interconnexions » 
            </strong> */}
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Voir plus"
        imageSrc={
          "/images/sipar.png"
        }
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
     
      
      <MainFeature2
        subheading={<Subheading></Subheading>}
        heading={<>Recensement & Taxe fonciere <HighlightedText>PAGCF</HighlightedText></>}
        description={
          <Description>
            Un logiciel de dématérialisation des procédures de recensement et de calcul de la taxe foncieres.
    
            <br />
            {/* LES COMPOSANTES : <br/> */}
            <Text>
              <ul>
                <li>
                  <p>WEB</p>
                </li>
                <li>
                  <p>Mobile</p>
                </li>
                <li>
                  <p>SIG</p>
                </li>
                <li>
                  <p>GED</p>
                </li>
                <li>
                  <p>Site des collectivités</p>
                </li> 
              </ul>
            </Text>
            {/* <strong>
            WEB /
            GED /
            Mobile /
            SIG /
            Site des collectivités
            </strong> */}
          </Description>
        }
        statistics={[
         
        ]}
        primaryButtonText="Voir plus"
        primaryButtonUrl="/pagcf"
        imageInsideDiv={false}
        imageSrc="/images/pagcf.png"
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      />
      
    </AnimationRevealPage>
  );
}
