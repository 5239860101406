import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line


import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "../headers/light.js";
import ResponsiveVideoEmbed from "../../helpers/ResponsiveVideoEmbed.js";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none `}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw``}
  }
  ${NavToggle}.closed {
    ${tw`text-pink-700 hover:text-pink-700`}
  }
`;
const Container = styled.div` 
  ${tw`relative -mx-8 -mt-8 bg-center bg-cover`}
  background-image: url("/images/parner.jpg");
`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const TwoColumn = tw.div`min-h-[800px] py-12 pt-24 pb-32 px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const Heading = styled.h1`
  ${tw`text-3xl text-center lg:text-left sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-none`}
  span {
    ${tw`inline-block mt-2`}
  }
`;

const SlantedBackground = styled.span`
  ${tw`relative text-pink-700 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;
const SlantedBackgrounds = styled.span`
  ${tw`relative text-pink-700 px-4 -mx-4 py-2`}
  &::before {
    content: "";
    ${tw`absolute inset-0 bg-gray-100 transform -skew-x-12 -z-10`}
  }
`;
const Notification = tw.span`inline-block my-4 pl-3 py-1 text-pink-700  border-l-4 border-pink-700 font-medium text-sm`;

const PrimaryAction = tw(NavLink)`px-8 py-3 mt-10 text-sm sm:text-base sm:mt-16 sm:px-8 sm:py-4 bg-pink-100 text-pink-700 font-bold rounded shadow transition duration-300 hocus:bg-pink-700 hocus:text-gray-100 focus:shadow-outline`;

const StyledResponsiveVideoEmbed = styled(ResponsiveVideoEmbed)`
  padding-bottom: 56.25% !important;
  padding-top: 0px !important;
  ${tw`rounded`}
  iframe {
    ${tw`rounded bg-black shadow-xl`}
  }
`;


export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">
        Accueil
      </NavLink>
      <NavLink href="/realisation">
        Réalisations
      </NavLink>
      <NavLink href="/reference">
        Réferences
      </NavLink>
      <NavLink href="/formation">
        Formations
      </NavLink>
      <NavLink href="/a-propos">
        A propos
      </NavLink>
      <NavLink href="/contact">
        Contacts
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/contact">
        Nous Contacter
      </PrimaryLink>
    </NavLinks>
  ];

  return (
    <>
    {/* <Slider /> */}
    <Container>
      
      {/* <OpacityOverlay /> */}
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <TwoColumn>
        
          <LeftColumn>
            <Notification>Groupe IDYAL basé a Dakar, Sénégal.</Notification>
            <Heading>
            <SlantedBackgrounds><span>L'innovation </span></SlantedBackgrounds>
            <br />
            <SlantedBackground><span> au service</span></SlantedBackground>
              <br />
              <SlantedBackground>de vos IDEES.</SlantedBackground>
            </Heading>
            <br/>
            <br/>
            <br/>
            <PrimaryAction  href="/contact"> Parlons de votre projet</PrimaryAction>
          </LeftColumn>
          <RightColumn>
            <StyledResponsiveVideoEmbed
              url="//player.vimeo.com/video/801166088?title=0&Like=0&portrait=0&byline=0&autoplay=0&responsive=1"
              background="transparent"
            />
            {/* <ReactPlayer
              url='https://www.youtube.com/watch?v=W0kjadC1UIo'
              background="transparent"
            /> */}
          </RightColumn>
         
        </TwoColumn>
      </HeroContainer>
      <div  color="white">
        <img color="white" src="/images/header-shape.svg" alt=""/>
      </div>
    </Container>
    </>
  );
};
