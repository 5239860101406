import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header, { NavLink, LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Vision from "components/features/vision.js";
import Features from "components/features/ThreeColWithSideImage.js";
import TechnologieUse from "components/features/TechnologieUse";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none `}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-900 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-pink-700 hover:text-pink-700`}
  }
`;
export default () => {
  
  return (
    <>
    <StyledHeader><Header /></StyledHeader>
    <AnimationRevealPage>
      
      <MainFeature1
        subheading={<Subheading>A propos de</Subheading>}
        heading="Groupe idyal."
        buttonRounded={false}
        primaryButtonText="Voir Réalisations"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <Vision
        subheading={<Subheading>Notre Vision</Subheading>}
        heading="Groupe idyal."
        buttonRounded={false}
        primaryButtonText="Nous contacter"
        imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      <div class="plyr__video-embed" id="player">
  {/* <iframe
    src="https://www.youtube.com/embed/bTqVqk7FSmY?origin=https://plyr.io&amp;iv_load_policy=3&amp;modestbranding=1&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1"
    allowfullscreen
    allowtransparency
    allow="autoplay"
  ></iframe> */}
      </div>
      <Features />
      <FeatureStats />
      <TechnologieUse />
      {/* <TeamCardGrid 
        subheading={<Subheading>L'équipe</Subheading>}
      /> */}
      <Footer />
    </AnimationRevealPage>
    </>
  );
};
