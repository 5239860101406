import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading } from "components/misc/Headings.js";

import defaultCardImage from "images/shield-icon.svg";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import Developer from "images/developer.svg";
import Backup from "images/fast.svg";
import Audit from "images/audit.svg";
import Licence from "images/licence.svg";

const Container = tw.div`relative`;
const SectionDescriptions = tw(DescriptionBase)`text-center mx-auto text-gray-600 max-w-4xl`;
const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;

const Heading = tw(SectionHeading)`w-full`;


const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-16 h-16`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;
const HighlightedText = tw.span`bg-gray-400 text-pink-100 px-4 transform -skew-x-12 inline-block`;
export default ({ cards = null, heading = "Amazing Features", subheading = "Features", description = "Une gamme complète de solutions pour répondre aux besoins de nos clients. Nos services incluent la gestion de projets, le développement de logiciels, la mise en réseau, la sécurité informatique, la sauvegarde de données, la maintenance informatique et le support technique." }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    { imageSrc: Developer, title: "Développement de Logiciels" },
    { imageSrc: Backup, title: "Backup & Déploiement" },
    { imageSrc: Audit, title: "Conseils & Audit" },
    { imageSrc: Licence, title: "Formation" },
    // {
    //   imageSrc: Network,
    //   title: "Ingénieuries Réseaux & Télécom",
    // },
    // { imageSrc: Support, title: "Support technique" }
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
      <Heading>Nos <HighlightedText><span tw="text-pink-700">Services</span></HighlightedText></Heading>
        {/* {subheading && <Subheading>{subheading}</Subheading>} */}
       
        {/* {description && <Description>{description}</Description>} */}
        <SectionDescriptions>
        Une gamme complète de solutions pour répondre aux besoins de nos clients. Nos services incluent la gestion de projets, le développement de logiciels, la mise en réseau, la sécurité informatique, la sauvegarde de données, la maintenance informatique et le support technique.
    </SectionDescriptions>
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                {/* <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p> */}
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
